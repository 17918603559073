import React from "react";
import ScanReturns from "./ScanReturns";
import Help from "./Help.svg";
import logo from "./LOGO red bird.svg";

const Homepage = () => {
  return (
    <div>
      <ScanReturns />
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://app.nuclino.com/Birdsnest/Software-Team/Returns-App-Instructions-for-Birds-1ab6d1e2-4bd0-4648-be06-dd63854f8571"
      >
        <img src={Help} alt="Help Icon" className="help-icon"></img>
      </a>
      <img src={logo} alt="Logo" className="logo-redbird-homepage"></img>
    </div>
  );
};

export default Homepage;
