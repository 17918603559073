import React from "react";
import { Row, Col } from "antd";
import warningicon from "./ICON Warning.svg";

function ReturnConfirmation(props) {
  return (
    <div className="return-confirmation-bar">
      <Row>
        <Col span={16}>
          <img src={warningicon} alt="Warning" className="warning-icon" />{" "}
          Return marked as an exception. Place paperwork in exception pile
        </Col>
      </Row>
    </div>
  );
}

export default ReturnConfirmation;
