import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import OrderDetails from "./OrderDetails";
import logo from "./LOGO red bird.svg";
import ErrorBoundary from "./ErrorBoundary";
import ScanItem from "./ScanItem";
import { Divider } from "antd";
import HighlightRow from "./HighlightRow";
import ProcessButtons from "./ProcessButtons";
import SystemException from "./SystemException";
import { useParams } from "react-router-dom";
import AddOrRemoveItem from "./AddOrRemoveItem";

const Returns = (props) => {
  let { orderid } = useParams();
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [orderData, setOrderData] = useState(null);
  const [itemsArrived, setItemsArrived] = useState([]);

  function updateItemArrived(checked, plu) {
    setItemsArrived(AddOrRemoveItem(itemsArrived, plu, checked));
  }

  function onChange(checked, plu, returning) {
    let iterms_arrived = orderData.orderitems;
    console.log(!returning, plu, iterms_arrived);
    updateItemArrived(checked, plu);
  }

  useEffect(() => {
    const getUserMetadata = async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          audience: process.env.REACT_APP_AUDIENCE,
          scope: "read:returns update:returns",
        });

        const returnsUrl =
          process.env.REACT_APP_RETURNS_URL + "/orders/" + orderid;

        console.log(returnsUrl);

        const metadataResponse = await fetch(returnsUrl, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const userMetadata = await metadataResponse.json();

        setOrderData(userMetadata);
      } catch (e) {
        document.location = "/notfound";
        console.log(e.message);
      }
    };
    getUserMetadata();
  }, [getAccessTokenSilently, user?.sub, orderid]);

  return (
    isAuthenticated && (
      <div className="order-info">
        {orderData ? (
          <div>
            <ErrorBoundary>
              <ScanItem
                orderitems={orderData.orderitems}
                updateItemArrived={updateItemArrived}
                onChange={onChange}
              ></ScanItem>
              <OrderDetails
                bc_order_id={orderData.bc_order_id}
                order_date={orderData.order_date}
                customer_name={orderData.customer_name}
                payment_method={orderData.payment_method}
              />
              <div class="orderitems">
                {orderData.orderitems.map((orderitems) => (
                  <HighlightRow
                    orderitems={orderitems}
                    updateItemArrived={updateItemArrived}
                    onChange={onChange}
                    itemsArrived={itemsArrived}
                  ></HighlightRow>
                ))}
              </div>
            </ErrorBoundary>
            <Divider className="divider divider-items" />
            <SystemException
              orderitems={orderData.orderitems}
            ></SystemException>
            <ErrorBoundary>
              <ProcessButtons
                setFlash={props.setFlash}
                orderitems={orderData.orderitems}
                bc_order_id={orderData.bc_order_id}
                itemsArrived={itemsArrived}
              ></ProcessButtons>
            </ErrorBoundary>
          </div>
        ) : (
          <div className="returns-loading">
            <ErrorBoundary>
              <img src={logo} alt="Logo" className="logo-redbird" /> Loading
              Order Details
            </ErrorBoundary>
          </div>
        )}
      </div>
    )
  );
};

export default Returns;
