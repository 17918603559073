import React from "react";
import { Alert } from "antd";

function OrderExceptionError(props) {
  let message = "Error:",
    type = "error",
    descriptionText = "Unknown Error.";

  if (props.message === "error") {
    descriptionText = "Error marking as exception.";
  } else if (props.message === "no-items") {
    message = "Warning:";
    type = "warning";
    descriptionText = "No items selected.";
  } else if (props.message === "no-radio") {
    message = "Warning:";
    type = "warning";
    descriptionText = "No options selected.";
  } else if (props.message === "Order over 365 days.") {
    message = "Warning:";
    type = "warning";
    descriptionText =
      "Order over 365 days.";
  }

  return (
    <>
      {(() => {
        if (props.message === "Order over 365 days.") {
          return (
            <Alert
              className="invalid-order-error"
              message={message}
              description={`${descriptionText} Please proceed with MARK AS EXCEPTION.`}
              type={type}
              closable
              onClose={props.onClose}
            ></Alert>
          );
        } else {
          return (
            <Alert
              className="invalid-order-error"
              message={message}
              description={`${descriptionText} Please check and try again.`}
              type={type}
              closable
              onClose={props.onClose}
            ></Alert>
          );
        }
      })()}
    </>
  );
}

export default OrderExceptionError;
