import React from "react";

function PaymentMethod(props) {
  // Payment method is usually a string,
  // but may be an object with manual-payment details.
  switch (typeof(props.payment_method)) {
    case 'string':
      return props.payment_method;

    default:
      let { name, description } = props.payment_method;
      if (name.toLowerCase() === description.toLowerCase()) {
        return description;
      } else {
        return (
            <>
              {name} payment<br/>
              <span className="order-payment-note">{description}</span>
            </>
        );
      }
  }
}

export default PaymentMethod;
