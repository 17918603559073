function AddOrRemoveItem(list, item, add) {
  if (add) {
    var itemsIndex = list.indexOf(item);
    console.log("itemsIndex", itemsIndex);
    if (itemsIndex < 0) {
      console.log("itemsArrived added", list.concat([item]));
      return list.concat([item]);
    } else {
      console.log("itemsArrived", list);
      return list;
    }
  } else {
    console.log("Filter itemsArrived");
    return Array.from(list).filter((uncheckedItem) => item !== uncheckedItem);
  }
}

export default AddOrRemoveItem;
