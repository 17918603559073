import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import "./App.css";
import LoginButton from "./components/Login";
import Returns from "./components/Returns";
import Nav from "./components/Nav";
import { Switch, Route } from "react-router-dom";
import Homepage from "./components/Homepage";
import NotFound from "./components/NotFound";
import ReturnConfirmation from "./components/ReturnConfirmation";

function App() {
  const { isloading } = useAuth0();
  const [flash, setFlash] = useState(null);

  if (isloading) return <div>Loading...</div>;

  return (
    <>
      <div>
        <header>
          <Nav />
        </header>
        {(() => {
          if (flash) {
            return <ReturnConfirmation />;
          }
        })()}
        <Switch>
          <Route path="/" exact component={LoginButton} />
          <Route path="/homepage" exact component={Homepage} />
          <Route path="/notfound" exact component={NotFound} />
          <Route path="/orders/:orderid">
            <Returns setFlash={setFlash} />
          </Route>
        </Switch>
      </div>
    </>
  );
}

export default App;
