import { Row, Col } from "antd";
import React from "react";
import { Checkbox } from "antd";

function OrderItems(props) {
  return (
    <div>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={4}>
          <div>STYLE CODE</div>
        </Col>
        <Col span={6}>
          <div>ITEM DESCRIPTION</div>
        </Col>
        <Col span={2}>
          <div>SIZE</div>
        </Col>
        <Col span={3}>
          <div>COLOUR</div>
        </Col>
        <Col span={2}>
          <div>PLU</div>
        </Col>
        <Col span={4}>
          <div>QUANTITY</div>
        </Col>
        <Col span={3}>
          <div>RETURN</div>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={4}>
          <div>{props.style_code} </div>
        </Col>
        <Col span={6}>
          <div>{props.item_description}</div>
        </Col>
        <Col span={2}>
          <div>{props.size}</div>
        </Col>
        <Col span={3}>
          <div>{props.colour} </div>
        </Col>
        <Col span={2}>
          <div>{props.plu} </div>
        </Col>
        <Col span={4}>
          <div>{props.quantity}</div>
        </Col>
        <Col span={3}>
          <div>
            {(() => {
              if (props.status.includes("returned")) {
                return <Checkbox checked disabled></Checkbox>;
              } else {
                return (
                  <Checkbox
                    id="return-checkbox"
                    checked={props.returning ? true : false}
                    onChange={(event) =>
                      props.onChange(event.target.checked, props.plu)
                    }
                  ></Checkbox>
                );
              }
            })()}
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default OrderItems;
