import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "antd";
import Logo from "./Logo";

const LoginButton = () => {
  const { loginWithRedirect, isAuthenticated } = useAuth0();

  return (
    !isAuthenticated && (
      <>
        <Logo />
        <Button
          className="getstarted-button"
          title="login-button"
          type="primary"
          onClick={() => loginWithRedirect()}
        >
          GET STARTED
        </Button>
      </>
    )
  );
};

export default LoginButton;
