import React, { useState } from "react";
import { Button, Radio, Space } from "antd";
import OrderExceptionError from "./OrderExceptionError";

function CustomException({ closePopup, confirmReturn }) {
  const [cValue, setCValue] = useState(0);
  var [custom_exception, setCustomExceptionMessage] = useState("");
  const [radio, setRadio] = useState(1);

  function onChangeRadio(e) {
    console.log("radio checked", e.target.value);
    setCValue(e.target.value);
  }

  function onChangeInput(e) {
    console.log(e.target.value);
    if (e.target.value === "custom_exception") {
      setCustomExceptionMessage("");
    } else {
      setCustomExceptionMessage(e.target.value);
    }
  }

  function radioSelection() {
    if (cValue === 0) {
      console.log("No radio selected");
      setRadio(0);
    }
  }
  
  return (
    <div>
      <div className="popup-background"></div>
      <div className="popup-container">
        <div className="pop-close-header">
          <Button
            className="popup-close-button"
            onClick={() => {
              closePopup();
              setCustomExceptionMessage("");
            }}
          >
            {" "}
            &times;{" "}
          </Button>
        </div>
        <div className="popup-title">
          <div>MARK RETURN AS EXCEPTION</div>
        </div>
        <div className="popup-body">
          <Radio.Group onChange={onChangeRadio} value={cValue}>
            <Space direction="vertical">
              <Radio value={"notes_on_paperwork"} onChange={onChangeInput}>
                Extra instructions on paperwork
              </Radio>
              <br></br>
              <Radio value={"custom_exception"} onChange={onChangeInput}>
                Other
                {cValue === "custom_exception" ? (
                  <>
                    <input
                      className="custom-exception-inputbox"
                      placeholder="Type in other reason for exception"
                      onChange={onChangeInput}
                      required
                      aria-required="true"
                    />
                    <p>(Fill this input)</p>
                  </>
                ) : null}
              </Radio>
              <Radio value={"over_365_days"} onChange={onChangeInput}>
                Over 365 days
              </Radio>
            </Space>
          </Radio.Group>
          {radio === 0 ? (
            <OrderExceptionError
              message="no-radio"
              onClose={() => {
                setRadio(1);
              }}
            ></OrderExceptionError>
          ) : (
            ""
          )}
        </div>
        <div>
          <Button
            className="popup-footer"
            onClick={() => {
              confirmReturn(custom_exception);
              radioSelection();
            }}
          >
            CONFIRM AND PROCESS
          </Button>
        </div>
      </div>
    </div>
  );
}

export default CustomException;
