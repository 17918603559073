import React from "react";
import OrderItems from "./OrderItems";
import ReturnDetails from "./ReturnDetails";
import { Row, Col } from "antd";

function HighlightRow(props) {
  var returning;
  console.log("itemsArrived", props.itemsArrived);
  returning = props.itemsArrived.includes(props.orderitems.plu);
  console.log("returning", returning);

  return (
    <Row className={`item-layout ${returning ? "checkbox-selection" : ""}`}>
      <Col span={2}>
        <img src={props.orderitems.image_url} alt="" className="item-image" />
      </Col>
      <Col span={22}>
        <OrderItems
          onChange={props.onChange}
          key={props.orderitems.item_id}
          style_code={props.orderitems.style}
          plu={props.orderitems.plu}
          image_url={props.orderitems.image_url}
          item_description={props.orderitems.description}
          size={props.orderitems.size}
          colour={props.orderitems.color}
          quantity={props.orderitems.quantity}
          status={props.orderitems.returns.map((returns) => returns.status)}
          returning={returning}
        />
        {props.orderitems.returns.map((returns, index) => (
          <ReturnDetails
            key={index}
            status={returns.status}
            reason={returns.reason}
            customer_comment={returns.customer_comment}
            date={returns.date}
            quantity_returned={returns.quantity}
          />
        ))}
      </Col>
    </Row>
  );
}
export default HighlightRow;
