import React from "react";
import { Button } from "antd";
import warningicon from "./ICON Warning.svg";

function ItemNotInOrder(props) {
  return (
    <div>
      <div className="popup-background"></div>
      <div className={"popup-container popup-container-noitem"}>
        <div className="popup-title">
          <img
            src={warningicon}
            alt="Warning"
            className={"warning-icon warning-icon-noitem"}
          />
          <div className="noitem-message-title">
            THIS ITEM IS NOT IN THIS ORDER
          </div>
          <div className="noitem-message">
            <ul>
              <li>
                Is the item in the order? If so, manually tick the return box.
              </li>
              <li>
                If not, is there another return form in the parcel? If so,
                please complete this return first then process item separately
                using the other form
              </li>
              <li>
                If not, put the item aside and mark as an exception for later
                processing.
              </li>
            </ul>
          </div>
        </div>
        <Button
          className="popup-footer popup-footer-noitem"
          onClick={() => {
            props.closePopup();
          }}
        >
          CONFIRM AND CONTINUE
        </Button>
      </div>
    </div>
  );
}

export default ItemNotInOrder;
