import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import LogoutButton from "./Logout";
import Profile from "./Profile";
import logo from "./LOGO bird White.svg";
import { Link } from "react-router-dom";

function Nav() {
    
    const {nav,isAuthenticated } = useAuth0();

    return (

        isAuthenticated && (
        <nav>            
            <div>                
                <Link className="header-link" to='/homepage'> <img src={logo} alt="Logo" className="logo-whitebird" />RETURNS</Link>
            </div>         
            <Profile /> 
            <LogoutButton />           
        </nav>)
    );
}

export default Nav;