import { Row, Col } from "antd";
import React from "react";

function ReturnDetails(props) {
  var return_class;
  if (props.status.includes("returning")) {
    return_class = "item-layout-returning";
  } else if (props.status.includes("returned")) {
    return_class = "item-layout-returned";
  }
  return (
    <div>
      <Row gutter={24} className={`returns-header ${return_class}`}>
        <Col span={4}>
          <div>RETURN REASON</div>
        </Col>
        <Col span={6}>
          <div>CUSTOMER COMMENT</div>
        </Col>
        <Col span={5}>
          <div></div>
        </Col>
        <Col span={8}>
          <div>QUANTITY RETURNED</div>
        </Col>
      </Row>
      <Row gutter={24} className={return_class}>
        <Col span={4}>
          <div>{props.reason} </div>
        </Col>
        <Col span={6}>
          <div>{props.customer_comment}</div>
        </Col>
        <Col span={5}>
          <div></div>
        </Col>
        <Col span={8}>
          <div>{props.quantity_returned} </div>
        </Col>
      </Row>
    </div>
  );
}

export default ReturnDetails;
