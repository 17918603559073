import React, { Component } from "react";
import BarcodeReader from "react-barcode-reader";
import ItemNotInOrder from "./ItemNotInOrder";
import SearchItemPlu from "./SearchItemPlu";

class ScanItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchItemResult: 0,
      scannedPlu: "",
    };
    this.plus = props.orderitems.map((orderitems) => orderitems.plu);
    this.handleItemScan = this.handleItemScan.bind(this);
    this.orderitems = props.orderitems;
  }

  handleItemScan(data) {
    this.setState({
      scannedPlu: data.slice(1),
    });
    this.findPLU(this.state.scannedPlu);
  }
  handleItemScanError(err) {
    console.error(err);
  }

  handleEnter(event) {
    var code = event.keyCode || event.which;
    if (code === 13) {
      this.findPLU(document.getElementById("arrived").value);
    }
  }

  closePopup() {
    this.setState({
      searchItemResult: 0,
      scannedPlu: "",
    });
  }

  findPLU(pluString) {
    console.log(this.plus);
    let plunum = parseInt(pluString, 10);
    console.log(plunum, "plunum");
    let searchResult = SearchItemPlu(this.plus, plunum);
    this.setState({
      searchItemResult: searchResult,
    });
    if (searchResult === 0) {
      console.log(this.orderitems, plunum, "check");
      this.props.onChange(true, plunum);
    }
    document.getElementById("arrived").value = "";
  }

  render() {
    return (
      <div>
        <BarcodeReader
          delay={300}
          onError={this.handleItemScanError}
          onScan={this.handleItemScan}
        />
        <label className="scan-product-orderpage">SCAN PRODUCT</label>
        <input
          id="arrived"
          className="scan-stylecode-input"
          maxLength={25}
          size="30"
          type="text"
          placeholder="Scan Item Barcode"
          onKeyPress={this.handleEnter.bind(this)}
          ref={(element) => element?.focus?.()}
          disabled={this.state.searchItemResult === 1 ? true : false}
        ></input>
        <label className="scan-product-tickcheckbox">
          OR tick return checkbox below
        </label>
        <br></br>
        {(() => {
          if (this.state.searchItemResult === 1) {
            return (
              <ItemNotInOrder
                closePopup={this.closePopup.bind(this)}
              ></ItemNotInOrder>
            );
          }
        })()}
      </div>
    );
  }
}

export default ScanItem;
