import React from "react";
import { Alert } from "antd";

const onClose = (e) => {
  console.log(e, "I was closed.");
  document.location = "/homepage";
};

const NotFound = () => (
  <div className="returns-loading">
    <Alert
      className="invalid-order-error"
      message="Error:"
      description="Sorry this doesn't look like a valid order barcode. Please check and try again."
      type="error"
      closable
      onClose={onClose}
    />
  </div>
);

export default NotFound;
