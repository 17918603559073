import React, { useState } from "react";
import { Button } from "antd";
import CustomException from "./CustomException";
import { useAuth0 } from "@auth0/auth0-react";
import { Redirect } from "react-router-dom";
import OrderExceptionError from "./OrderExceptionError";

function ProcessButtons(props) {
  const [message, setMessage] = useState(null);
  const [selection, setSelection] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);

  const { getAccessTokenSilently } = useAuth0();
  let bc_order_id = props.bc_order_id;

  let items_arrived = props.orderitems.filter(
    (orderitem) => props.itemsArrived.indexOf(orderitem.plu) >= 0
  );

  function markException() {
    const item = items_arrived.length;
    setSelection(item);
    if (item === 0) {
      console.log("No item slected");
    } else {
      setOpenPopup(true);
    }
  }

  function processException() {
    const item = items_arrived.length;
    setSelection(item);

    if (item === 0) {
      console.log("No item slected");
    } else {
      confirmReturn(true);
    }
  }

  const confirmReturn = async (processExceptionVal, custom_exception) => {
    let processReturn = {};
    processReturn.items_arrived = items_arrived.map((orderitems) => {
      let exceptions = orderitems.exception_messages;
      if (custom_exception === "notes_on_paperwork") {
        exceptions = [...orderitems.exception_messages, custom_exception];
      } else if (custom_exception) {
        exceptions = [...orderitems.exception_messages, `custom_exception`];
      }

      return {
        plu: orderitems.plu,
        quantity: orderitems.quantity,
        exception_messages: exceptions,
      };
    });

    let url =
      process.env.REACT_APP_RETURNS_URL +
      `/orders/${bc_order_id}/processed_returns`;

    if (custom_exception !== "") {
      processReturn[`custom_exception`] = custom_exception;
    } else if (!processExceptionVal) {
      console.log(processExceptionVal);
      return props.inputValidation;
    }

    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUDIENCE,
      scope: "update:returns",
    });

    fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(processReturn),
    })
      .then((response) => {
        if (!response.ok) {
          setMessage("error");
          console.log("error");
        } else {
          setMessage("success");
          props.setFlash(true);
          console.log("success");
          return response.json();
        }
      })
      .catch((error) => setMessage("Error:" + error));
  };

  return (
    <>
      <Button
        className="orderpage-button"
        type="primary"
        onClick={processException}
      >
        PROCESS EXCEPTION
      </Button>
      <Button
        className="orderpage-button"
        type="primary"
        onClick={markException}
      >
        MARK AS EXCEPTION
      </Button>
      {selection === 0 ? (
        <OrderExceptionError
          message="no-items"
          onClose={() => {
            setSelection(null);
          }}
        ></OrderExceptionError>
      ) : (
        ""
      )}
      {(() => {
        if (message === "success") {
          return <Redirect to="/homepage"></Redirect>;
        } else if (message === null) {
          return "";
        } else {
          return (
            <OrderExceptionError
              message={message}
              onClose={() => {
                setMessage(null);
              }}
            ></OrderExceptionError>
          );
        }
      })()}
      {openPopup && (
        <CustomException
          closePopup={() => {
            setOpenPopup(false);
          }}
          confirmReturn={(custom_exception) =>
            confirmReturn(false, custom_exception)
          }
        ></CustomException>
      )}
    </>
  );
}

export default ProcessButtons;
