function SearchItemPlu(plus, arrivedplu) {
  if (plus.indexOf(arrivedplu) < 0) {
    console.log("not found");
    return 1;
  } else {
    console.log("found");
    return 0;
  }
}

export default SearchItemPlu;
