import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "antd";

const LogoutButton = () => {
  const { logout, isAuthenticated } = useAuth0();

  return (
    isAuthenticated && (
    <Button  className="header-link" onClick={() => logout({ returnTo: window.location.origin })}>
      <div >LOG OUT</div>
    </Button>
  )
);
};

export default LogoutButton;