import React, { Component } from "react";
import BarcodeReader from "react-barcode-reader";
import { Button } from "antd";

class ScanReturns extends Component {
  constructor(props) {
    super(props);
    this.state = {
      result: "No result",
    };
    this.handleReturnsScan = this.handleReturnsScan.bind(this);
  }
  handleReturnsScan(data) {
    if (data.slice(1, 4) === "BC-")
      document.location = "orders/" + data.slice(1);
    else if (data.match(/019931265099999891/i)) {
      const tracker = data.match(/019931265099999891(([A-Z0-9]{3}|[A-Z0-9]{5})\d{18})/i);
      document.location = "orders/" + tracker[1];
    } else {
      document.location = "orders/" + data;
    }
  }
  handleReturnsError(err) {
    console.error(err);
  }
  findBCOrder() {
    if (document.getElementById("orderid").value.slice(0, 3) === "BC-")
      document.location = "orders/" + document.getElementById("orderid").value;
    else if (
      document.getElementById("orderid").value.match(/019931265099999891/i)
    ) {
      const tracker = document
        .getElementById("orderid")
        .value.match(/019931265099999891(([A-Z0-9]{3}|[A-Z0-9]{5})\d{18})/i);
      document.location = "orders/" + tracker[1];
    } else {
      document.location = "orders/" + document.getElementById("orderid").value;
    }
  }
  handleEnter(event) {
    var code = event.keyCode || event.which;
    if (code === 13) {
      this.findBCOrder();
    }
  }

  render() {
    return (
      <div>
        <BarcodeReader
          delay={300}
          onError={this.handleReturnsError}
          onScan={this.handleReturnsScan}
        />
        <label htmlFor="orderid" className="homepage-label">RETURN ORDER</label>
        <input
          id="orderid"
          className="homepage-input"
          maxLength={72}
          size="72"
          type="text"
          placeholder="Scan Auspost or return form barcode or type order ID with prefix 'BC-'"
          onKeyPress={this.handleEnter.bind(this)}
        ></input>
        <Button className="homepage-start" onClick={this.findBCOrder}>
          START RETURN
        </Button>
      </div>
    );
  }
}

export default ScanReturns;
