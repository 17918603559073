import React from "react";
import { useHistory } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";

const Auth0ProviderWithHistory = ({ children }) => {
    const domain = process.env.REACT_APP_AUTH0_DOMAIN;
    const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
    const audience = process.env.REACT_APP_AUDIENCE;
      
    const history = useHistory();
  
    const onRedirectCallback = (appState) => {
      history.push(appState?.returnTo || '/homepage');
    };
  
    return (
      <Auth0Provider
        domain={domain}
        clientId={clientId}
        redirectUri={window.location.origin}
        onRedirectCallback={onRedirectCallback}
        audience={audience}
        scope="read:returns update:returns"
      >
        {children}
      </Auth0Provider>
    );
  };
  
  export default Auth0ProviderWithHistory;