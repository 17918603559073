import React, { Component } from "react";
import { Alert } from "antd";
import "antd/dist/antd.css";

const onClose = (e) => {
  console.log(e, "I was closed.");
  document.location = "/homepage";
};

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError(error) {
    return {
      hasError: true,
    };
  }

  render() {
    if (this.state.hasError) {
      return (
        <Alert
          className="invalid-order-error"
          message="Error:"
          description="Sorry this doesn't look like a valid order barcode. Please check and try again."
          type="error"
          closable
          onClose={onClose}
        />
      );
    }
    return this.props.children;
  }
}
export default ErrorBoundary;
