import React from "react";
import { Row, Col, Divider } from "antd";
import PaymentMethod from "./PaymentMethod";
import OrderExceptionError from "./OrderExceptionError";

function OrderDetails(props) {
  const orderDate=props.order_date.slice(0, 10);
  const currentDate=new Date().toISOString().slice(0, 10);
  const d1=Date.parse(orderDate);
  const d2=Date.parse(currentDate);
  const daysSinceOrdered=(d2-d1)/(1000 * 3600 * 24)
  console.log(orderDate,currentDate)
  console.log(daysSinceOrdered)
  return (
    <>
      <div className="order-details">ORDER DETAILS</div>
      <div className="order-info">
        <Row gutter={16}>
          <Col className="gutter-row" span={12}>
            <div>BC ORDER ID : {props.bc_order_id}</div>
          </Col>
          <Col className="gutter-row" span={12}>
            <div>ORDER PLACED : {props.order_date.slice(0, 10)}</div>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col className="gutter-row" span={12}>
            <div>CUSTOMER NAME : {props.customer_name}</div>
          </Col>
          <Col className="gutter-row" span={12}>
            <div>
            DAYS SINCE ORDERED : {daysSinceOrdered} Days
            </div>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col className="gutter-row" span={12}>
            <div></div>
          </Col>
          <Col className="gutter-row" span={12}>
            <div>
              PAYMENT METHOD : <PaymentMethod payment_method={props.payment_method} />
            </div>
          </Col>
        </Row>
      </div>
      { daysSinceOrdered > 365 ? (
        <OrderExceptionError
          message="Order over 365 days."
          onClose={() => {
          }}
        ></OrderExceptionError>
      ) : (
        ""
      )}
      <Divider className="divider divider-order" />
      <div className="order-items-header">ORDER #{props.bc_order_id} ITEMS</div>
    </>
  );
}

export default OrderDetails;
