import React from "react";
import logo from "./LOGO red bird.svg";
import background from "./IMAGEbirdsnestreturns.jpg";

function Logo() {
  return (
    <div>
      <img src={background} alt="" className="background-logo" />
      <h1 className="get-started-company">birdsnest</h1>,
      <h1 className="get-started-app">returns</h1>,
      <img src={logo} alt="Logo" className="logo-redbird" />
    </div>
  );
}

export default Logo;
