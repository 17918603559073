import React from "react";
import { Row, Col } from "antd";
import warningicon from "./ICON Warning.svg";

function SystemException(props) {
  let exception_messages = props.orderitems.flatMap(
    (orderitems) => orderitems.exception_messages
  );

  let unique_exception_message = new Set(exception_messages);

  if (exception_messages)
    return (
      <div className="exception-bar">
        <Row>
          <Col span={12}>
            <img src={warningicon} alt="Warning" className="warning-icon" />{" "}
            EXCEPTION:
          </Col>
          <Col span={1} className="exception-message">
            <>{unique_exception_message}</>
          </Col>
        </Row>
      </div>
    );
  else {
    return "";
  }
}

export default SystemException;
